import YouTube from "react-youtube";
import { motion } from "framer-motion";

import styles from "./home.module.scss";
import { variants } from "../../utils/variants";

// import { AppContext } from '../../AppContext';

import HomeIntro from "./home-intro";
import HomeBanner from "./home-banner";
import HomeAbout from "./home-about";
import HomePersonalize from "./home-personalize";
import HomeFeatures from "./home-features";
import HomeMoreFeatures from "./home-more-features";
import HomeInstall from "./home-install";
import Footer from "../footer/footer";
// import HomeProcess from './home-process';

// import { ReactComponent as Logo } from '../../icons/logo.svg';

export default function Home() {
  // const introRef = useRef(null);
  // const [stats, setStats] = useState([]);
  // const [bannerHeight, setBannerHeight] = useState("100vh");

  // if (!content.active) {
  //   return <div>Loading...</div>;
  // }

  const opts = {
    height: "1280",
    width: "1920",
    playerVars: {
      autoplay: 1,
      controls: 1,
      color: "white",
      showinfo: 0,
      background: 1,
      fs: 0,
      loop: 1,
      playlist: "O01klOCcSAw",
      mute: 1,
      iv_load_policy: 3,
      disablekb: 1,
      cc_load_policy: 0,
      enablejsapi: 1,
      modestbranding: 1,
      rel: 0,
      playsinline: 1,
      origin: window.location.origin,
    },
    loop: 1,
    autoplay: 1,
    autohide: 1,
    modestbranding: 1,
    showinfo: 0,
    controls: 0,
  };

  // const updateHeight = () => {
  //   if (introRef.current && introRef.current.clientHeight) {
  //     const height = `calc(100vh - ${introRef.current.clientHeight}px)`;
  //     setBannerHeight(height);
  //   } else {
  //     // Default to 100vh if introRef isn't ready yet
  //     setBannerHeight("100vh");
  //   }
  // };

  // useEffect(() => {
  //   updateHeight();

  //   const timeoutId = setTimeout(updateHeight, 50);

  //   window.addEventListener("resize", updateHeight);
  //   return () => {
  //     window.removeEventListener("resize", updateHeight);
  //     clearTimeout(timeoutId); // Clean up timeout
  //   };
  // }, []);

  // useEffect(() => {
  //   updateHeight();
  // }, [introRef]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("https://startyparty.dev/api/marketing", {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       const data = await response.json();
  //       console.log(data, "d");
  //       setStats(data);
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={styles.container}
    >
      <div className={styles.introWrap}>
        {/* Banner */}
        <div className={styles.bannerLimit}>
          <HomeBanner />
        </div>

        <div className={styles.introBackground}>
          <div className={styles.browserWindowOSX}>
            <div className={styles.browserWindowOSXHeader}>
              <div className={styles.browserWindowOSXMenu}>
                <span className={styles.browserWindowOSXClose}></span>
                <span className={styles.browserWindowOSXMinimize}></span>
                <span className={styles.browserWindowOSXMaximize}></span>
              </div>
              <div className={styles.browserWindowOSXSearch}>
                <a href="https://startyparty.dev">
                  <span>https://</span>staryparty.dev
                </a>
              </div>
            </div>
            <div className={styles.browserWindowOSXWindow}>
              <YouTube
                videoId={"O01klOCcSAw"}
                className={`${styles.youtube}`}
                opts={opts}
              // onReady={handleVideoReady}
              // onEnd={endPlayback}
              // onError={endPlayback}
              // onStateChange={onStateChange}
              />
            </div>
          </div>
        </div>

        {/* First Row - Intro */}
        <HomeIntro />
      </div>

      {/* About */}
      <div className={styles.containerInner}>
        <HomeAbout />
      </div>

      {/* Features */}
      <HomeFeatures />

      {/* Personalize */}
      <div className={styles.containerInner}>
        <HomePersonalize />
      </div>

      {/* Markets */}
      <HomeMoreFeatures />

      {/* Contact */}
      <div className={styles.containerInner}>
        <HomeInstall />
      </div>

      {/* Footer */}
      <Footer />
    </motion.div>
  );
}
