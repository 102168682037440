import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import styles from "./footer.module.scss";

const Marquee = ({ text }) => {
  const ref = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const width = ref.current ? ref.current.offsetWidth - (ref.current.offsetWidth / 3) : 0;
  const randomNumber = Math.floor(Math.random() * 5) + 1;

  const vars = {
    animate: {
      x: [0, -width],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: width * 0.033,
          ease: "linear",
        },
      },
    },
  };

  useEffect(() => {
    setIsAnimating(true);
  }, []);

  return (
    <span className={styles.marquee}>
      <motion.h2
        className={styles.track}
        variants={vars}
        animate="animate"
        key={isAnimating}
        ref={ref}
      >
        {text}
        <img
          src={`/map/icon__plant.png`}
          alt="placeholder"
        />
        {text}
        <img
          src={`/map/icon__ship--${randomNumber}.png`}
          alt="placeholder"
        />
        {text}
        <img
          src={`/map/icon__ship--${randomNumber}.png`}
          alt="placeholder"
        />
        {text}
        <img
          src={`/map/icon__ship--${randomNumber}.png`}
          alt="placeholder"
        />
        {text}

      </motion.h2>
    </span>
  );
};

export default Marquee;
