import { motion } from "framer-motion";

import styles from "./footer.module.scss";
import { variants } from "../../utils/variants";

import Marquee from "./marquee";

import { ReactComponent as IconMail } from "../../icons/icon__email.svg";
import { ReactComponent as IconTwitter } from "../../icons/icon__twitter.svg";
import { ReactComponent as IconInstagram } from "../../icons/icon__instagram.svg";
import { ReactComponent as IconMastodon } from "../../icons/icon__mastodon.svg";
import { ReactComponent as IconDiscord } from "../../icons/icon__discord--alt.svg";
// import { ReactComponent as IconLinkedin } from "../../icons/icon__linkedin.svg";
// import { ReactComponent as IconYoutube } from "../../icons/icon__watchlater.svg";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <motion.article
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants}
        className={`${styles.footerInner}`}
      >
        <div className={styles.footerColumn}>
          <div className={styles.footerSocial}>
            <ul>
              <li>
                <a
                  href="mailto:startyparty@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconMail />
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/32HjYnctSg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconDiscord />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/startypartydev/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://mastodon.social/@startypartydev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconMastodon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/startypartydev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconTwitter />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <a
            href="https://startyparty.dev/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <div className={styles.footerColumn}>
          <a
            href="https://startyparty.dev/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </div>
      </motion.article>

      {/* Large Marquee */}
      <div className={styles.marqueeContainer}>
        <Marquee text="startyparty  is a dynamic and continuously evolving project, always incorporating the newest articles, media, tech, features and content." />
      </div>

      <div className={styles.footerCopyright}>
        <p>
          © Copyright 2024 <strong>startyparty</strong>.
        </p>
        <p>All rights reserved.</p>
        <p className={styles.footerSpacer} />
        <a
          href="https://marko.tech"
          target="_blank"
          rel="noreferrer"
          className={styles.version}
        >
          By <strong>Marko Bajlovic</strong>
        </a>
      </div>
    </footer>
  );
}
