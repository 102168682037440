// import React, { useState } from 'react';
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";

import { variants } from "../../utils/variants";

import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper-overrides.scss";
import styles from "./home.module.scss";

import { Pagination, Navigation } from "swiper/modules";

export default function HomeFeatures() {
  return (
    <div className={`${styles.outer} ${styles.values}`}>
      <div className={styles.valuesContainerInner}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article}`}
        >
          <div className={styles.content}>
            <div className={`${styles.contentInner} ${styles.contentInnerBW}`}>
              <h3>
                This project, my labor of love for many years, has evolved into
                a streamlined homepage that compiles top-tier articles, news,
                tweets, podcasts, videos, games, and more into a unified,
                launcher-style web application.
              </h3>
              <h3>
                <strong>startyparty</strong> is a dynamic and continuously
                evolving project, always incorporating the newest articles,
                media, tech, features and content.
              </h3>
            </div>

            <div className={styles.valuesSpacer} />

            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                "--swiper-navigation-active-color": "#fff",
                "--swiper-pagination-bullet-active-color": "#fff",
                "--swiper-pagination-bullet-inactive-color": "#fff",
                "--swiper-navigation-size": "30px",
              }}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              navigation={true}
              modules={[EffectCreative, Pagination, Navigation]}
              className={styles.slider}
            >
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__videos.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>videos</h3>
                  <p>
                    Dive into the latest videos; startyparty dynamically brings
                    you the newest videos from YouTube, ensuring you're always
                    in the loop with engaging and fresh content at your
                    fingertips.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__videos--player.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>video player</h3>
                  <p>
                    Discover the ease of watching all videos right within
                    startyparty. Stay engaged with the world of media without
                    ever having to leave the app, all thanks to our streamlined
                    video feature.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__podcasts.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>podcasts</h3>
                  <p>
                    Stay up to date with fresh episodes of your favorite
                    podcasts with startyparty. startyparty keeps you in the
                    loop, ensuring you never miss out on top audio content.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__podcasts__player.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>podcasts player</h3>
                  <p>
                    Enjoy seamless podcast streaming directly within
                    startyparty, where every episode plays through an intuitive,
                    built-in player. Transitioning between episodes and shows is
                    effortless, ensuring a continuous and enjoyable listening
                    and discovery experience.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__news.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>the latest news</h3>
                  <p>
                    startyparty fetches the latest news by aggregating content
                    from hundreds of diverse sources, ensuring a wide array of
                    perspectives and topics. No algorithmic bias, just the
                    facts.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__news--tag.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>news deep dives</h3>
                  <p>
                    With links to these sources, users can explore in-depth
                    articles, breaking news, and exclusive stories by publisher
                    or by tag. Dive into the news that interests you most, all
                    within the startyparty app.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__games--inline.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>games</h3>
                  <p>
                    startyparty integrates a diverse collection of web-based
                    games directly within the app. From classic puzzles to
                    cutting-edge adventures, every game is designed to run
                    smoothly in your browser without the need to download or
                    install anything.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__search.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>search</h3>
                  <p>
                    startyparty's search makes finding anything in the app easy.
                    You can search any content type or even search Google or
                    DuckDuckGo (those searches open in new windows).
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__steam.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>steam launcher</h3>
                  <p>
                    Play games on Steam? Launch games straight from startyparty.
                    Additionally, it features comprehensive links to game
                    analytics, offering insights into gameplay statistics,
                    achievements, and hours played, ensuring gamers have all the
                    necessary data to enhance their gaming experience.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__music--all.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>music</h3>
                  <p>
                    Discover an ever-evolving music experience with
                    startyparty's YouTube music feature. Every day, fresh tracks
                    are added, ensuring you always have access to the latest
                    hits and hidden gems. Seamlessly stream your favorite tunes
                    directly within the app. Build your own playlist on the go.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__twitch.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>twitch</h3>
                  <p>
                    Stay connected with the live pulse of gaming and creative
                    content with startyparty's Twitch feed. This feature curates
                    a list of top streamers, showing you who's currently online.
                    Jump into the action with just one click, ensuring you never
                    miss out on your favorite streamers' live broadcasts.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.articleHeader} ${styles.articleHeaderRight}`}
        >
          <h2 className={styles.articleHeaderNumber}>02</h2>
          <h2 className={styles.articleHeaderTitle}>features</h2>
        </motion.article>
      </div>
    </div>
  );
}
