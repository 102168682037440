import { motion } from "framer-motion";

import { variants } from "../../utils/variants";

import styles from "./home.module.scss";

export default function HomeAbout() {
  const articleHeaderClasses = `${styles.articleHeader}`;
  const articleClasses = `${styles.article} ${styles.articlePadding}`;
  const contentInnerClasses = `${styles.contentInner} ${styles.contentInnerDescription}`;

  return (
    <div className={`${styles.outer} ${styles.about}`}>
      <motion.article
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants}
        className={articleHeaderClasses}
      >
        <h2 className={styles.articleHeaderNumber}>01</h2>
        <h2 className={styles.articleHeaderTitle}>about</h2>
      </motion.article>

      <motion.article
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants}
        className={articleClasses}
      >
        <div className={styles.content}>
          <div className={contentInnerClasses}>
            <h3>
              Welcome to your new start page for the web. If you love diving
              into the latest in news, tech, podcasts, videos, media and more,{" "}
              <strong>startyparty</strong> is here for you.
            </h3>
            <h3>
              It's the ultimate, privacy first, launchpad for power users and
              readers. The web, at a glance.
            </h3>
          </div>
        </div>
      </motion.article>
    </div>
  );
}
