import { motion } from "framer-motion";
import { variants } from "../../utils/variants";
import { ReactComponent as IconChrome } from "../../icons/icon__chrome.svg";
import { ReactComponent as IconFirefox } from "../../icons/icon__firefox.svg";
import { ReactComponent as IconBrave } from "../../icons/icon__brave.svg";
import { ReactComponent as IconGithub } from "../../icons/icon__github.svg";
import { ReactComponent as IconStartyparty } from "../../icons/icon__startyparty.svg";

import styles from "./home.module.scss";

const ffStatsURL = `https://img.shields.io/badge/dynamic/json?url=https%3A%2F%2Fraw.githubusercontent.com%2Farbitrarily%2Fstartpage-addons%2Fmaster%2Ffirefox%2Fmanifest.json&query=%24.version&logo=firefox&label=Firefox%20Version`;
const chromeStatsURL = `https://img.shields.io/badge/dynamic/json?url=https%3A%2F%2Fraw.githubusercontent.com%2Farbitrarily%2Fstartpage-addons%2Fmaster%2Fchrome%2Fmanifest.json&query=%24.version&logo=googlechrome&label=Chrome%20Version`;
const gitStatsURL = `https://img.shields.io/github/last-commit/arbitrarily/startpage-addons?display_timestamp=committer`;
const upStatsURL = `https://img.shields.io/website?url=https%3A%2F%2Fstartyparty.dev%2F`;

export default function HomeInstall() {
  return (
    <div className={`${styles.outer} ${styles.contact}`}>
      <div className={`${styles.contactInnerHeader}`}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.articleHeader}`}
        >
          <h2 className={styles.articleHeaderTitle}>install yours</h2>
          <h3>
            Get the <strong>startyparty</strong> browser extension now.
          </h3>
          <h3>
            No trackers, no ads, no analytics, no bloating, and absolutely no
            third-party analytics. Pure browsing joy with privacy at its core.
          </h3>
        </motion.article>
      </div>

      <div className={`${styles.contactInner}`}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article} ${styles.articlePadding}`}
        >
          <div className={styles.content}>
            <div
              className={`${styles.contentInner} ${styles.contentInnerDescription}`}
            >
              <div className={styles.content}>
                <div
                  className={`${styles.contentInner} ${styles.contentInnerDescription}`}
                >
                  <a
                    href="https://addons.mozilla.org/en-US/firefox/addon/startyparty/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3>
                      <IconFirefox className={styles.iconLarge} />
                      Firefox
                      <div className={styles.iconBadge}>
                        <img src={ffStatsURL} alt="Firefox Version" />
                      </div>
                    </h3>
                  </a>
                  <a
                    href="https://chromewebstore.google.com/detail/startyparty/niboekcakmfpliephbcjgoepgnffalme"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3>
                      <IconChrome className={styles.iconLarge} />
                      Chrome
                      <div className={styles.iconBadge}>
                        <img src={chromeStatsURL} alt="Chrome Version" />
                      </div>
                    </h3>
                  </a>
                  <a
                    href="https://chromewebstore.google.com/detail/startyparty/niboekcakmfpliephbcjgoepgnffalme"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3>
                      <IconBrave className={styles.iconLarge} />
                      Brave
                      <div className={styles.iconBadge}>
                        <img src={chromeStatsURL} alt="Brave Version" />
                      </div>
                    </h3>
                  </a>
                  <a
                    href="https://startyparty.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3>
                      <IconStartyparty className={styles.iconLarge} />
                      startyparty
                      <div className={styles.iconBadge}>
                        <img src={upStatsURL} alt="Brave Version" />
                      </div>
                    </h3>
                  </a>
                  <a
                    href="https://github.com/arbitrarily/startyparty-addons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3>
                      <IconGithub className={styles.iconLarge} />
                      Github
                      <div className={styles.iconBadge}>
                        <img src={gitStatsURL} alt="Brave Version" />
                      </div>
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.article>
      </div>
    </div>
  );
}
