import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Home from "./components/home/home";

// Motion Variants
import { variantsFade, variantsFadeBackground } from "./utils/variants";

import { randomBGNumber } from "./utils/utility";

import styles from "./styles/app.module.scss";

function Container() {
  const [BG, setBG] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setBG(randomBGNumber());
  }, []);

  return (
    <main className={styles.main}>
      <AnimatePresence>
        {/* Background */}
        {BG && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variantsFadeBackground}
            transition={{
              duration: 2,
              ease: "easeInOut",
            }}
            key={BG}
          >
            <div
              className={styles.background}
              style={{
                backgroundImage: `url(https://startyparty.nyc3.cdn.digitaloceanspaces.com/backgrounds/thumb/${BG}.jpg)`,
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Header */}
      <AnimatePresence key={`header`}>
        <div className={styles.header}>
          {/* Navigation */}
          {/* <Navigation /> */}
        </div>
      </AnimatePresence>

      {/* Container */}
      <motion.div
        className={styles.container}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={variantsFade}
        ref={ref}
        key={BG}
      >
        <AnimatePresence>
          {/* Links Navigations */}
          <Home />
        </AnimatePresence>

        {/* <Feed
            key={activeFeed.feed}
            feed={activeFeed}
            feedNav={handleFeedNav}
            paginationNav={handlePaginationNav}
          /> */}
      </motion.div>
    </main>
  );
}

export default Container;
