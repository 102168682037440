import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { variants, variantsPlayer } from "../../utils/variants";

import styles from "./home.module.scss";

export default function HomePersonalize() {
  const [activeService, setActiveService] = useState({
    img: "https://startyparty.nyc3.cdn.digitaloceanspaces.com/backgrounds/art/0126.jpg",
    title: "Web Design & Development",
    description:
      "Galaxies globular star cluster muse about the carbon in our apple pies two ghostly white figures i1",
  });

  const handleService = (e, d) => {
    e.preventDefault();
    setActiveService({
      img: d.img,
      title: d.title,
      description: d.description,
    });
  };

  return (
    <div className={`${styles.outer} ${styles.services}`}>
      <motion.article
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants}
        className={`${styles.articleHeader}`}
      >
        <h2 className={styles.articleHeaderNumber}>03</h2>
        <h2 className={styles.articleHeaderTitle}>personalize</h2>
      </motion.article>

      <div className={styles.articleRight}>
        <h3 className={styles.articleLead}>
          <strong>startyparty</strong> offers a one-time <span>$25</span> paid
          membership that grants account access with unique features for
          logged-in users, enhancing your experience with exclusive content,
          customization, and privileges.
        </h3>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article}`}
        >
          <div className={`${styles.articleColumn}`}>
            <AnimatePresence>
              {activeService.title && ( // Assuming title is unique and indicative of a service being set
                <motion.article
                  initial="hidden"
                  animate="visible" // Switched to animate for testing
                  exit="hidden"
                  variants={variantsPlayer}
                  className={`${styles.articleTarget}`}
                  key={activeService.title} // Use a unique property of activeService as key
                >
                  <>
                    <img src={activeService.img} alt={activeService.title} />
                    <h3>{activeService.title}</h3>
                    <p>{activeService.description}</p>
                  </>
                </motion.article>
              )}
            </AnimatePresence>
          </div>

          <div className={styles.content}>
            <div className={`${styles.contentInner}`}>
              <ul className={styles.contentInnerList}>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__shortcuts.jpg",
                      title: "Keyboard Shortcuts",
                      description:
                        "startyparty incorporates keyboard shortcuts designed for power users, enabling efficient navigation and interaction for an enhanced user experience.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__shortcuts.jpg",
                      title: "Keyboard Shortcuts",
                      description:
                        "startyparty incorporates keyboard shortcuts designed for power users, enabling efficient navigation and interaction for an enhanced user experience.",
                    })
                  }
                >
                  <h3>Keyboard Shortcuts</h3>
                  <p>Navigate quickly</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__settings--customize.jpg",
                      title: "Customize UI",
                      description:
                        "Utilize the settings panel to tailor the application's interface to your preferences, allowing for a more personalized and efficient user experience.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__settings--customize.jpg",
                      title: "Customize UI",
                      description:
                        "Utilize the settings panel to tailor the application's interface to your preferences, allowing for a more personalized and efficient user experience.",
                    })
                  }
                >
                  <h3>Customize UI</h3>
                  <p>Optimize the experience</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__settings.jpg",
                      title: "Personalize",
                      description:
                        "Explore the settings to make startyparty truly yours. Customize and adjust settings to enhance your visit.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__settings.jpg",
                      title: "Personalize",
                      description:
                        "Explore the settings to make startyparty truly yours. Customize and adjust settings to enhance your visit.",
                    })
                  }
                >
                  <h3>Personalize</h3>
                  <p>Make it yours</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__readability.jpg",
                      title: "Reader View",
                      description:
                        "startypary features a reader view that strips away clutter like buttons, ads, background images, and videos to directly read the written content.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__readability.jpg",
                      title: "Reader View",
                      description:
                        "startypary features a reader view that strips away clutter like buttons, ads, background images, and videos to directly read the written content.",
                    })
                  }
                >
                  <h3>Reader View</h3>
                  <p>Straight to the point</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__history.jpg",
                      title: "History States",
                      description:
                        "startyparty remembers the content you've engaged with and greys it out once engaged with.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__history.jpg",
                      title: "History States",
                      description:
                        "startyparty remembers the content you've engaged with and greys it out once engaged with.",
                    })
                  }
                >
                  <h3>History States</h3>
                  <p>Know what content you've seen</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__saved.jpg",
                      title: "Exclusive Sort, Categories & Filtering",
                      description:
                        "Save your favorite videos, articles, and podcasts to access them at your convenience, allowing for a personalized content experience that fits into your schedule.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__saved.jpg",
                      title: "Save for Later",
                      description:
                        "Save your favorite videos, articles, and podcasts to access them at your convenience, allowing for a personalized content experience that fits into your schedule.",
                    })
                  }
                >
                  <h3>Save for Later</h3>
                  <p>Get to it another time</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__filters.jpg",
                      title: "Exclusive Sort, Categories & Filtering",
                      description:
                        "startyparty offers advanced sorting, category selection, and filtering options to help you quickly find and organize content based on your preferences.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__filters.jpg",
                      title: "Exclusive Sort, Categories & Filtering",
                      description:
                        "startyparty offers advanced sorting, category selection, and filtering options to help you quickly find and organize content based on your preferences.",
                    })
                  }
                >
                  <h3>Exclusive Sort, Categories & Filtering</h3>
                  <p>Dive deeper</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__follow.jpg",
                      title: "Follow & Unfollow",
                      description:
                        "startyparty allows users to follow and unfollow publishers, series, and channels to stay up to date with the latest content and discover new content from their favorite creators.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__follow.jpg",
                      title: "Follow & Unfollow",
                      description:
                        "startyparty allows users to follow and unfollow publishers, series, and channels to stay up to date with the latest content and discover new content from their favorite creators.",
                    })
                  }
                >
                  <h3>Follow & Unfollow</h3>
                  <p>Curate your feeds</p>
                </li>
                <li
                  onClick={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__exclusive--feeds.jpg",
                      title: "Exclusive Feeds",
                      description:
                        "Get access to member only feeds and content as well as early access to all forthcoming features.",
                    })
                  }
                  onMouseOver={(e) =>
                    handleService(e, {
                      img: "screenshots/ss__exclusive--feeds.jpg",
                      title: "Exclusive Feeds",
                      description:
                        "Get access to member only feeds and content as well as early access to all forthcoming features.",
                    })
                  }
                >
                  <h3>Exclusive Feeds</h3>
                  <p>Unique content and sections</p>
                </li>
              </ul>
            </div>
            <p className={styles.contentParagraph}>
              This money goes towards paying monthly infrastructure costs and
              potentially helping a solo dev stay afloat.
            </p>
          </div>
        </motion.article>
      </div>
    </div>
  );
}
