import { motion } from "framer-motion";

import { variants } from "../../utils/variants";

import styles from "./home.module.scss";

import { ReactComponent as IconChevronDown } from "../../icons/icon__chevron--down.svg";

export default function HomeIntro() {
  return (
    <div className={styles.intro}>
      <motion.article
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants}
        className={`${styles.article} ${styles.articlePadding} ${styles.articleBorderRight}`}
      >
        <div className={styles.content}>
          <div className={styles.contentInner}>
            {/* <h2>
              At [Agency Name], we craft captivating digital experiences through
              web design, photography, and branding, empowering businesses to
              connect, engage, and inspire.
            </h2> */}
            <p className={styles.contentInnerAlign}>
              <IconChevronDown />
              Scroll Down to Learn More
            </p>
          </div>
        </div>
      </motion.article>
    </div>
  );
}
