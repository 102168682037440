import { motion } from "framer-motion";

import { variantsBanner } from "../../utils/variants";

import styles from "./home.module.scss";

import { ReactComponent as Logo } from "../../logo.svg";
import { ReactComponent as IconFirefox } from "../../icons/icon__firefox.svg";
import { ReactComponent as IconChrome } from "../../icons/icon__chrome.svg";
import { ReactComponent as IconGithub } from "../../icons/icon__github.svg";
import { ReactComponent as IconStartyParty } from "../../icons/icon__startyparty.svg";

export default function HomeBanner() {
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;
  var isFirefox = typeof InstallTrigger !== "undefined";

  let url = "";

  if (isChrome) {
    url =
      "https://chromewebstore.google.com/detail/startyparty/niboekcakmfpliephbcjgoepgnffalme";
  } else if (isEdgeChromium) {
    url =
      "https://chromewebstore.google.com/detail/startyparty/niboekcakmfpliephbcjgoepgnffalme";
  } else if (isFirefox) {
    url = "https://addons.mozilla.org/en-US/firefox/addon/startyparty/";
  } else {
    url = "https://startyparty.dev/";
  }

  return (
    <div className={styles.banner}>
      <motion.article
        initial="hidden"
        animate="visible"
        variants={variantsBanner}
        className={`${styles.article}`}
      >
        <div className={styles.bannerLogo}>
          <Logo className={styles.logo} />
          <p>the start of it all</p>
        </div>
        <div className={styles.bannerLocation}>
          <a className={styles.button} href={url}>
            {isFirefox && <IconFirefox className={styles.icon} />}
            {isChrome ||
              (isEdgeChromium && <IconChrome className={styles.icon} />)}
            {url.includes("github.com") && (
              <IconGithub className={styles.icon} />
            )}
            {url.includes("startyparty.dev") && (
              <IconStartyParty className={styles.icon} />
            )}
            <strong>
              {url.includes("startyparty.dev")
                ? "Launch startyparty"
                : "Get the Extension"}
            </strong>
          </a>
        </div>
      </motion.article>
    </div>
  );
}
