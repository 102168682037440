// import styles from '../components/article/article.module.scss';

export function timeStringToUnixSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours > 0 ? hours.toString().padStart(1, '0') : '';
  let formattedMinutes = Math.floor(minutes).toString().padStart(2, '0');
  const formattedSeconds = Math.floor(remainingSeconds).toString().padStart(2, '0');

  // Remove leading zero from minutes if hours is 0 and minutes is less than 10
  if (formattedHours === '' && minutes < 10) {
    formattedMinutes = formattedMinutes.substring(1);
  }

  return `${formattedHours !== '' ? formattedHours + ':' : ''}${formattedMinutes}:${formattedSeconds}`;
}

export function formatUnixTimestamp(timestamp) {
  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const dayOfWeek = dayNames[date.getDay()];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();

  const time = `${hour % 12 === 0 ? 12 : hour % 12}${hour >= 12 ? 'pm' : 'am'}`;
  const formattedString = `${dayOfWeek}, ${month} ${day} @ ${time}`;

  return formattedString;
}

export function formatNumberWithSuffix(number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(0) + 'k';
  } else {
    return number;
  }
}

export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getPercentDiff = (a, b) => {
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  if (isNaN(numA) || isNaN(numB)) {
    throw new Error("One or both inputs are not valid numbers.");
  }

  if (numA === numB) return 0;

  const difference = Math.abs(numA - numB);
  const average = (numA + numB) / 2;
  const percentageDifference = (difference / average) * 100;

  return parseFloat(percentageDifference.toFixed(3));
}

export const getDifference = (a, b) => {
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  if (isNaN(numA) || isNaN(numB)) {
    throw new Error("One or both inputs are not valid numbers.");
  }

  if (numA === numB) return 0;

  const difference = Math.abs(numA - numB);
  return formatNumberWithCommas(difference);
};

export function timeAgo(unixTimestamp) {
  if (!unixTimestamp || unixTimestamp < 0) return null;

  const now = new Date().getTime();
  const secondsPast = (now - unixTimestamp * 1000) / 1000;

  if (secondsPast < 60) {
    return "just now";
  }

  if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes}m`;
  }

  if (secondsPast < 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return `${hours}h`;
  }

  if (secondsPast < 604800) {
    const days = Math.floor(secondsPast / 86400);
    if (days === 1) {
      return "yesterday";
    }
    return `${days}d`;
  }

  if (secondsPast < 31536000) {
    const weeks = Math.floor(secondsPast / 604800);
    if (weeks === 1) {
      return "1w";
    }
    return `${weeks}w`;
  }

  const years = Math.floor(secondsPast / 31536000);
  return `${years}y`;
}

export function timeAgoInSeconds(unixTimestamp) {
  if (!unixTimestamp || unixTimestamp < 0) return null;

  const now = new Date().getTime();
  const secondsPast = Math.floor((now - unixTimestamp * 1000) / 1000);

  return (
    <>
      {secondsPast}s <span> ago</span>
    </>
  )
}

// Check if Image Exists
export function checkIfImageExists(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(url); // Image loaded successfully
    img.onerror = () => resolve("fallback.png"); // Image failed to load

    img.src = url;
  });
};

// Clean Up Strings
export function replaceSpecialCharacters(title) {
  if (!title) return;
  const replacements = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&nbsp;': ' ',
    '&ldquo;': '"',
    '&rdquo;': '"',
    '&#x27;': "'",
    '&rsquo;': "'",
    '&lsquo;': "'",
    '&ndash;': '-',
    '&mdash;': '-',
    '&hellip;': '...',
    '&trade;': '™',
    '&reg;': '®',
    '&copy;': '©',
    '&bull;': '•',
    '&deg;': '°',
    '&plusmn;': '±',
    '&times;': 'x',
    '&divide;': '÷',
    '&frac12;': '½',
    '&frac14;': '¼',
    '&frac34;': '¾',
    '&micro;': 'µ',
    '&para;': '¶',
    '&middot;': '·',
    '&laquo;': '«',
    '&raquo;': '»',
    '&ordf;': 'ª',
    '&ordm;': 'º',
    '&iquest;': '¿',
  };

  return title.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;|&nbsp;|&ldquo;|&rdquo;|&rsquo;|&lsquo;|&ndash;|&mdash;|&hellip;|&trade;|&reg;|&copy;|&bull;|&deg;|&plusmn;|&times;|&divide;|&frac12;|&frac14;|&frac34;|&micro;|&para;|&middot;|&laquo;|&raquo;|&ordf;|&ordm;|&iquest;/g,
    (match) => {
      return replacements[match];
    }
  );
}

// Random Number Generator
export function randomBGNumber() {
  return (Math.floor(Math.random() * 260) + 1).toString().padStart(4, "0");
}

// Slugify String
export function slugify(string) {
  return string
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
}

// Make Sure URL is HTTPS
export function ensureHttps(url) {
  if (url === 'fallback.png' || url === '/fallback.png') return url;

  const parsedUrl = new URL(url);

  if (parsedUrl.protocol !== "https:") {
    parsedUrl.protocol = "https:";
    return parsedUrl.href;
  }

  return url;
}
