// import React, { useState } from 'react';
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";

import { variants } from "../../utils/variants";

import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper-overrides.scss";
import styles from "./home.module.scss";

import { ReactComponent as IconDiscord } from "../../icons/icon__discord.svg";

import { Pagination, Navigation } from "swiper/modules";

export default function HomeMoreFeatures() {
  return (
    <div className={`${styles.outer} ${styles.outerValues}`}>
      <div className={`${styles.values} ${styles.containerInner}`}>
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.article}`}
        >
          <div className={styles.content}>
            <div className={`${styles.contentInner} ${styles.contentInnerBW}`}>
              <h3>
                Startyparty offers a one-time $25 paid membership that grants
                account access with unique features for logged-in users,
                enhancing the overall experience with exclusive content and
                privileges.
              </h3>
            </div>

            <div className={styles.valuesSpacer} />

            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                "--swiper-navigation-active-color": "#fff",
                "--swiper-pagination-bullet-active-color": "#fff",
                "--swiper-pagination-bullet-inactive-color": "#fff",
                "--swiper-navigation-size": "30px",
              }}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              navigation={true}
              modules={[EffectCreative, Pagination, Navigation]}
              className={styles.slider}
            >
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__git.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>github</h3>
                  <p>
                    Startyparty contains a feed with the latest and most popular
                    Github repositories, enabling users to stay updated with the
                    newest project releases and updates.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__lemmy.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>lemmy</h3>
                  <p>
                    Startyparty integrates a dynamic Lemmy, an alternative to
                    Reddit, showcasing the latest discussions and content
                    directly from Lemmy's vibrant community.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__mastodon.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>mastodon</h3>
                  <p>
                    Startyparty aggregates the top journalists' feeds on
                    Mastodon, a Twitter alternative, by curating a list of
                    influential voices and news sources. This feature allows
                    users to access a diverse range of perspectives and breaking
                    news, directly from the forefront of journalism.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__media-calendar.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>media calendar</h3>
                  <p>
                    Startyparty continously fetches and updates the schedules of
                    upcoming popular shows and movies. See what's coming up next
                    and never miss out.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__news--publishers.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>publishers, series, channels</h3>
                  <p>
                    Startyparty provides a list of publishers, series, and
                    channels to explore.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__videos--tags.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>tag navigation</h3>
                  <p>
                    Startyparty offers a tag navigation system to delve deeper
                    into a topic's content.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__music--artist.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>artist navigation</h3>
                  <p>
                    Explore music by artist clicking any artist name to see
                    filter by artist.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.sliderSlide}>
                <div className={styles.sliderSlideInner}>
                  <img src="screenshots/ss__search--results.jpg" alt="" />
                </div>
                <div className={styles.sliderSlideContent}>
                  <h3>search all content</h3>
                  <p>
                    Search delinates all content on Startyparty by post type.
                    Use the search to find what you're looking for.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </motion.article>

        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants}
          className={`${styles.articleHeader} ${styles.articleHeaderRight}`}
        >
          <h2 className={styles.articleHeaderNumber}>04</h2>
          <h2 className={styles.articleHeaderTitle}>features</h2>
          <div className={`${styles.contentInner}`}>
            <p>
              Join the{" "}
              <a
                href="https://discord.gg/32HjYnctSg"
                target="_blank"
                rel="noreferrer"
                className={styles.servicesLink}
              >
                <IconDiscord />
                <span>Discord</span>
              </a>{" "}
              to stay updated on the latest features and updates.
            </p>
          </div>
        </motion.article>
      </div>
    </div>
  );
}
